.material-symbols-rounded {
  font-variation-settings:
  'FILL' 1,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

.icon_dashboard:before{
    content: "dashboard";
}

.icon_inventory:before{
    content: "inventory";
}

.icon_part:before{
    content: "construction";
}

.icon_sales::before{
    content: "point_of_sale";
}

.icon_purchase::before{
    content: "shopping_cart";
}

.icon_supplier::before{
    content: "group";
}

.icon_customer::before{
    content: "add_business";
}

.icon_production::before{
  content: "precision_manufacturing";
}

.icon_human_resource::before{
  content: "badge";
}

.icon_configuration::before{
    content: "settings";
}

.icon_resource::before{
    content: "room_preferences";
}

.icon_workflow::before{
    content: "account_tree";
}

.icon_quality::before{
    content: "verified";
}

.icon_planning::before{
    content:"query_stats";
}

.icon_accounting::before{
    content:"account_balance";
}

.icon_service_order::before{
    content:"build_circle";
}

.icon_service_order::before{
    content:"build_circle";
}

.icon_job_request::before{
    content:"send";
}

.icon_admin::before{
    content:"admin_panel_settings";
}

.icon_shipping::before{
    content:"local_shipping";
}

.icon_contract_management::before{
    content:"contract";
}

.icon_play_circle::before{
    content: "play_circle";
    font-size: 64px;
}

.icon_stop_circle::before{
    content: "stop_circle";
    font-size: 64px;
}

.icon_pause_circle::before{
    content: "pause_circle";
    font-size: 64px;
}

.icon_project_management::before{
    content:"task";
}

.icon_open_new_tab::before{
    content:"open_in_new";
}

.icon-monitoring::before{
    content: "monitoring";
}

.icon-load::before{
    font-size: 48px;
    content: "download";
    margin-right: 25px;
}

.icon-load-small::before{
    font-size: 24px;
    content: "download";
}

.icon-start::before{
    font-size: 48px;
    content: "play_circle";
    margin-right: 25px;
}

.icon-start-small::before{
    font-size: 24px;
    content: "play_circle";
}

.icon-unload::before{
    font-size: 48px;
    content: "upload";
    margin-right: 25px;
}

.icon-unload-small::before{
    font-size: 24px;
    content: "upload";
}

.icon-ok::before{
    font-size: 48px;
    content: "check_circle";
    margin-right: 25px;
}

.icon-ok-small::before{
    font-size: 24px;
    content: "check_circle";
}

.icon-nok::before{
    font-size: 48px;
    content: "cancel";
    margin-right: 25px;
}

.icon-nok-small::before{
    font-size: 24px;
    content: "cancel";
}

.icon-pause::before{
    font-size: 48px;
    content: "pause_circle";
    margin-right: 25px;
}

.icon-pause-small::before{
    font-size: 24px;
    content: "pause_circle";
}

.icon-stop::before{
    font-size: 48px;
    content: "stop_circle";
    margin-right: 25px;
}

.icon-stop-small::before{
    font-size: 24px;
    content: "stop_circle";
}

.icon-next::before{
    font-size: 48px;
    content: "arrow_forward_ios";
    margin-right: 25px;
}

.icon-previous::before{
    font-size: 48px;
    content: "arrow_back_ios_new";
    margin-right: 25px;
}

.icon-resume::before{
    font-size: 48px;
    content: "resume";
    margin-right: 25px;
}

.icon-load-inventory::before{
    content: "add_shopping_cart";
}

.icon-unload-inventory::before{
    content: "remove_shopping_cart";
}

.icon-trolley::before{
    content: "trolley";
}

.icon_transportation::before{
    content: "forklift";
}

.icon_report::before{
    content: "summarize";
}