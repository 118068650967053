/* You can add global styles to this file, and also import other style files */

.grid-cell-detail-link {
  text-decoration: underline;
  cursor: pointer;
}


.root-container {
  display: flex;
  flex-direction: column;
}


/* */
iframe {
  border: none;
}

.report-container {
  height: 75vh;
  margin: 8px auto;
  width: 100%;
}


.imageClass {
  height: 80px;
  display: block;
  align-items: center;
  cursor: pointer;
}

.form-large-screen {
  width: 50%;
}

.form-small-screen {
  width: 90%;
}

.custom-form-container{
  display: flex;
  flex-direction: row;
  justify-content: center;
}